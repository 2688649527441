var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-container" },
    [
      _c("div", { staticClass: "head-box df fdr alc jc-sb" }, [
        _c("div", { staticClass: "df alc" }, [
          _c("img", {
            staticClass: "head-img",
            attrs: { src: _vm.head_img, alt: "head_img" },
          }),
          _c("div", { staticClass: "info-box df fdc als jc-sb my-sty" }, [
            _c("div", [_vm._v(_vm._s(_vm.use_info.nickname))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "order-box" }, [
        _c(
          "div",
          {
            staticClass: "order-title df fdr alc jc-sb",
            on: {
              click: function ($event) {
                return _vm.orderList("ALL")
              },
            },
          },
          [
            _c("div", { staticClass: "left" }, [_vm._v("我的订单")]),
            _c("div", { staticClass: "right" }, [_vm._v("查看全部订单")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "order-type df fdr alc jc-sb" },
          _vm._l(_vm.orderIcon, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "order-item df fdc alc jc-sb",
                on: {
                  click: function ($event) {
                    return _vm.orderList(item.key)
                  },
                },
              },
              [
                _c("icon-svg", {
                  staticClass: "main-color",
                  attrs: { "icon-class": item.icon_class },
                }),
                item.title == "待付款" && _vm.type == 3
                  ? _c("div", [_vm._v("待审批")])
                  : _c("div", [_vm._v(_vm._s(item.title))]),
                item.number > 99
                  ? _c(
                      "div",
                      { staticClass: "mark", staticStyle: { width: "0.4rem" } },
                      [_vm._v("99+")]
                    )
                  : item.number > 0
                  ? _c("div", { staticClass: "mark" }, [
                      _vm._v(_vm._s(item.number)),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "tab-list df fdc alc" },
        [
          _c(
            "router-link",
            {
              staticClass: "point tab-item df fdr alc my-point",
              attrs: { to: "" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/icon_sea_address.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "right-tab point-detail" }, [
                _c("div", [_vm._v("我的积分")]),
                _c("div", [_vm._v(_vm._s(_vm.use_info.integral))]),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "point tab-item df fdr alc",
              attrs: { to: "address" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/icon_sea_address.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "right-tab point-detail" }, [
                _vm._v("收货地址"),
              ]),
            ]
          ),
          _vm.ServiceStatus == 1
            ? _c(
                "div",
                {
                  staticClass: "point tab-item df fdr alc",
                  on: { click: _vm.openChat },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icon_sea_serve.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab point-detail" }, [
                    _vm._v("客服中心"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.ServiceStatus == 0
            ? _c(
                "a",
                {
                  staticClass: "point tab-item df fdr alc",
                  on: { click: _vm.openChat },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_sea_serve.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab point-detail" }, [
                    _vm._v("\n          客服中心\n        "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.sermob_my == true
            ? _c("div", { staticClass: "PopUp" }, [
                _c(
                  "div",
                  { staticClass: "bot-mobile", on: { click: _vm.openChat } },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/icon_sea_serve.png") },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        },
                      },
                    }),
                    _c("span", { staticClass: "service-phone" }, [
                      _vm._v("\n            客服电话:\n            "),
                      _c("span", [_vm._v(_vm._s(_vm.ServiceMobile))]),
                    ]),
                    _c("span", { staticClass: "dial" }, [
                      _c("a", { attrs: { href: "tel:" + _vm.ServiceMobile } }, [
                        _vm._v("确认呼叫"),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "cancel",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.cancel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "point tab-item df fdr alc",
              attrs: { to: "feedback" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/icon_sea_opinion.png"),
                  alt: "",
                },
              }),
              _c(
                "div",
                { staticClass: "right-tab feedbackStyle point-detail" },
                [_vm._v("意见反馈")]
              ),
            ]
          ),
          !_vm.icbcStatus
            ? _c(
                "router-link",
                {
                  staticClass: "point tab-item df fdr alc",
                  attrs: { to: "setting" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_sea_shezhi.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab point-detail" }, [
                    _vm._v("设置"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("yqt-foot"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }