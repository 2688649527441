<template>
  <div class="my-container">
    <div class="head-box df fdr alc jc-sb">
      <div class="df alc">
        <img :src="head_img" alt="head_img" class="head-img" />
        <!-- <div v-if="type !== 2" @click="toMyInfo" class="my-sty">{{nickname}}</div> -->
        <div class="info-box df fdc als jc-sb my-sty">
          <div>{{use_info.nickname}}</div>
        </div>
      </div>
    </div>

    <!-- 订单  -->
    <div class="order-box">
      <div @click="orderList('ALL')" class="order-title df fdr alc jc-sb">
        <div class="left">我的订单</div>
        <div class="right">查看全部订单</div>
      </div>
      <div class="order-type df fdr alc jc-sb">
        <div
          class="order-item df fdc alc jc-sb"
          v-for="(item, index) in orderIcon"
          :key="index"
          @click="orderList(item.key)"
        >
          <icon-svg class="main-color" :icon-class="item.icon_class" />
          <div v-if="item.title == '待付款' && type == 3">待审批</div>
          <div v-else>{{item.title}}</div>
          <div v-if="item.number > 99" class="mark" style="width: 0.4rem">99+</div>
          <div v-else-if="item.number > 0" class="mark">{{item.number}}</div>
        </div>
      </div>
    </div>
		<!-- 订单  -->

    <div class="tab-list df fdc alc">
      <!-- <div class="point">
        <div class="balanceImg"></div>
        <p class="point-detail border-bottom-1px">123</p>
      </div> -->
      <!-- <div class="point tab-item">
        <img src="../../assets/icon_sea_points.png" alt />
        <div class="point-detail">
          <div class="txt">我的积分</div>
          <p class="txt points">{{use_info.integral}}</p>
        </div>
      </div> -->
      <router-link to="" class="point tab-item df fdr alc my-point">
        <img src="../../assets/icon_sea_address.png" alt />
        <div class="right-tab point-detail">
          <div>我的积分</div>
          <div>{{use_info.integral}}</div>
        </div>
      </router-link>
      <router-link to="address" class="point tab-item df fdr alc">
        <img src="../../assets/icon_sea_address.png" alt />
        <div class="right-tab point-detail">收货地址</div>
      </router-link>
      <div
				v-if="ServiceStatus==1"
				@click="openChat"
				class="point tab-item df fdr alc"
			>
        <img src="~@/assets/icon_sea_serve.png" alt />
        <div class="right-tab point-detail">客服中心</div>
      </div>
      <!--todo 拨打电话-->
      <a v-if="ServiceStatus==0" class="point tab-item df fdr alc" @click="openChat">
        <img src="../../assets/icon_sea_serve.png" alt />
        <div class="right-tab point-detail">
          客服中心
        </div>
      </a>
      <div v-if="sermob_my==true" class="PopUp">
        <div class="bot-mobile" @click="openChat">
          <img src="~@/assets/icon_sea_serve.png" @click.stop="cancel"/>
          <span class="service-phone">
            客服电话:
            <span>{{ServiceMobile}}</span>
          </span>
          <span class="dial">
            <a :href="'tel:' + ServiceMobile">确认呼叫</a>
          </span>
          <span class="cancel" @click.stop="cancel">取消</span>
        </div>
      </div>
      <router-link to="feedback" class="point tab-item df fdr alc">
        <img src="../../assets/icon_sea_opinion.png" alt />
        <div class="right-tab feedbackStyle point-detail">意见反馈</div>
      </router-link>
      <router-link to="setting" class="point tab-item df fdr alc" v-if="!icbcStatus">
        <img src="../../assets/icon_sea_shezhi.png" alt />
        <div class="right-tab point-detail">设置</div>
      </router-link>
    </div>

    <yqt-foot></yqt-foot>
  </div>
</template>

<script>
import YqtFoot from "@/components/footer/yqt-footer.vue";
import { mapState } from "vuex";
import { getOrderNumber } from "@/services/my";

export default {
  name: 'specialIndex',
  data() {
    return {
      orderIcon: [
        { title: "待付款", key: "PAY", icon_class: "yi_icon_my_fukuan" },
        { title: "待发货", key: "DELIVER", icon_class: "yi_icon_my_fahuo" },
        { title: "待收货", key: "TAKE", icon_class: "yi_icon_my_shouhuo" },
        { title: "待评价", key: "EVALUATE", icon_class: "yi_icon_my_pingjia" },
        {
          title: "退货/款",
          key: "AFTER_SALE",
          icon_class: "yi_icon_my_tuikuan"
        }
      ],
    }
  },
  components: {
    YqtFoot
  },
  activated() {
    sessionStorage.route = this.$route.name;  
    this.getOrderNumber();
    this.$store.dispatch("getUserInfo");
    this.getUserAccountList()
  },
  computed: {
    ...mapState({
      //头像
      head_img: state =>
        state.userInfo.heagurl
          ? state.userInfo.heagurl
          : require("../../assets/ic_head_default@3x.png"),
      //用户信息
      use_info: state => state.userInfo,
      nickname: state =>
        state.userInfo.nickname
          ? state.userInfo.nickname
          : state.userInfo.mobile,
      bid: state => state.businessInfo.id,
      is_signed: state => state.userInfo.is_signed_today  // 是否签到
    })
  },
  methods: {
    async getOrderNumber() {
      try {
        const res = await getOrderNumber();
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.$set(this.orderIcon[0], "number", data.pre_pay);
          this.$set(this.orderIcon[1], "number", data.payed);
          this.$set(this.orderIcon[2], "number", data.delivered);
          this.$set(this.orderIcon[3], "number", data.confirmed);
          this.$set(this.orderIcon[4], "number", data.num);
        }
      } catch (e) {}
    },
    // 账户余额
    async getUserAccountList() {
      try {
        let params = {
          page: this.page,
          listRows: this.listRows,
          type: this.current_tab //1全部，2收入，3支出
        };
        const res = await getUserAccountList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          // console.log(90);
          console.log(data);
          this.money = data.money;
          console.log(this.money);
          this.list = [...this.list, ...data.list];
          this.total = data.total;
          if (this.list.length === data.total) {
            /* 所有数据加载完毕 */
            this.page++;
            this.$refs.account.$emit("ydui.infinitescroll.loadedDone");
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.account.$emit("ydui.infinitescroll.finishLoad");
        }
      } catch (e) {}
    },

     toDetail(key) {
     // window.location.href = `${baseUrl}/#/order_list?key=${key}`;
      this.$router.push({
        path: '/recode',
        query: {
          key: key
        }
      })
    },

    orderList(key) {
     // window.location.href = `${baseUrl}/#/order_list?key=${key}`;
      this.$router.push({
        path: '/order_list',
        query: {
          key: key
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

  .my-container {

    .head-box {
      position: relative;
      height: 2.4rem;
      background: @color-white;
      padding: 0.42rem 0.24rem 0.42rem 0.42rem;
      font-size: 0.38rem;
      margin-bottom: 0.2rem;

      .head-img {
        .wh(1.56rem, 1.56rem);
        border-radius: 50%;

        margin-right: 0.3rem;
      }

      .info-box {
        // height: 1.56rem;
        padding: 0.22rem 0;
        font-weight: 500;
        white-space: nowrap;

        .integral-box {
          .sc(0.24rem, #333);
          background: url("../../assets/icon_detail@3x.png") no-repeat right
            bottom;
          background-size: 0.32rem 0.32rem;
          padding-right: 0.32rem;
          white-space: nowrap;
        }

        .integral-number {
          .sc(0.44rem, #333);
          margin-right: 0.06rem;
          font-weight: bold;
        }
      }

      .detail_box {
        .wh(20%, 100%);

        .detail_icon {
          .wh(0.32rem, 0.32rem);
          .bis("../../assets/icon_detail");
        }
      }
    }

    .tab-list {
      background: @color-white;
      height: 2.64rem;

      .point {
        .wh(100%, 0.88rem);
        .sc(0.32rem, #333333);
        display: flex;
        justify-content: space-between;
        align-items: center;


        .point-detail {
          width: calc(100% - 0.48rem);
          height: 0.88rem;
          padding-right: 0.3rem;
          font-size: 100%;
          font-weight: normal;
          color: #333;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f5f5f5;

          .txt {
            line-height: 0.88rem;
          }

          .points {
            padding-right: 0.4rem;
          }
        }

        .points {
          padding-right: 0.3rem;
        }
      }

      .tab-item {
        .wh(100%, 0.88rem);
        .sc(0.32rem, #333333);
        background: white url(../../assets/icon_detail@3x.png) no-repeat 97% 50%;
        background-size: 0.4rem 0.4rem;

        img {
          .wh(0.48rem, 0.48rem);
          margin: 0 0.2rem 0 0.3rem;
        }

        .right-tab {
          .wh(100%, 100%);
          line-height: 0.88rem;
          span {
            color: #999;
            margin-left: 0.32rem;
          }
        }
        .feedbackStyle {
        }
      }

      .my-point {
        background: none;
      }
    }

    /*  订单*/

    .order-box {
      height: 2.44rem;
      background: @color-white;
      margin-bottom: 0.2rem;
      margin-top: 0.2rem;

      .order-title {
        height: 0.68rem;
        padding-right: 0.46rem;
        padding-left: 0.24rem;
        background: white url(../../assets/icon_more@3x.png) no-repeat 97% 50%;
        background-size: 0.14rem 0.24rem;

        .left {
          .sc(0.32rem, #2b2b2b);
        }

        .right {
          .sc(0.24rem, #999999);
        }
      }

      .order-type {
        height: 1.76rem;
        padding: 0.44rem 0.3rem 0.24rem 0.38rem;
        .sc(0.28rem, #999999);

        .order-item {
          height: 100%;
          position: relative;

          i {
            .sc(0.48rem, var(--main-color));
          }

          .mark {
            position: absolute;
            background: #ec5b55;
            border-radius: 50%;
            .wh(0.28rem, 0.28rem);
            .sc(0.2rem, @color-white);
            line-height: 0.28rem;
            text-align: center;
            right: 0;
            top: -0.28rem;
          }
        }
      }
    }
  }
</style>